import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import TestimonialSlide from './TestimonialSlide'

const SectionWrapper = styled.section`
  padding: 0;

  .carousel .slider {
    list-style: unset !important;
  }

  .carousel .slide {
    background: transparent;
  }

  .carousel.carousel-slider .control-arrow {
    padding: 2rem;
  }

  .carousel p {
    color: white;
  }

  @media screen {
    .carousel.carousel-slider .control-arrow {
      padding: 1rem !important;
    }
  }
`

const SectionHeading = styled.h2`
  margin: 1rem 0 3rem;
  font-weight: 100;
`

const TestimonialCarousel = ({ testimonialsAcf }) => {
  const testimonialIds = testimonialsAcf.map(
    edge => edge.testimonial.wordpress_id
  )
  const data = useStaticQuery(graphql`
    query TestimonialsQuery {
      allWordpressAcfTestimonial {
        edges {
          node {
            id
            wordpress_id
            acf {
              citation
              content
              featured_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const testimonials = data.allWordpressAcfTestimonial.edges
  // console.log(testimonials)

  if (testimonialIds.length === 0) {
    return null
  }

  const slides = testimonials.filter(testimonial => {
    const slidemap = Object.keys(testimonial.node).reduce((acc, curr) => {
      // console.log(acc, curr, testimonial.node[curr])
      return acc || testimonialIds.includes(testimonial.node[curr])
    }, false)
    // console.log(slidemap)
    return slidemap
  })

  return (
    testimonialIds && (
      <SectionWrapper>
        <span id="testimonials" />
        <SectionHeading className="is-size-2 has-text-centered">
          CLIENT TESTIMONIALS
        </SectionHeading>

        <Carousel
          autoPlay
          stopOnHover
          interval={5000}
          infiniteLoop
          transitionTime={100}
          showThumbs={false}
          showStatusshowStatus={false}
          showStatus={false}
          showIndicators={false}
        >
          {slides.map(testimonial => {
            return (
              <TestimonialSlide
                testimonial={testimonial.node}
                key={testimonial.node.id}
              />
            )
          })}
        </Carousel>
      </SectionWrapper>
    )
  )
}

export default TestimonialCarousel
